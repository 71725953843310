import React from "react"
import styled from "@emotion/styled";
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby";

const Section = styled.section`
    background-color: #f68507;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;

    @media (max-width: 60rem) {
        padding: 1rem;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 60rem;
    margin: 1rem 0;
`;

const Heading = styled.h3`
    color: #000;
    text-transform: uppercase;
    font-size: 2rem;
    margin: 0;
`;

const Copy = styled.p`
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
`;

const TileContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @media (max-width: 60rem) {
        flex-direction: column;
    }
`;

const Tile = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 1rem;
    text-decoration: none;

    @media (max-width: 60rem) {
        margin: 1rem 0;
    }
`;

const TileImage = styled(Img)`
    width: 100%;
    max-width: 290px;
`;

const QuotesSection = () => {
    const data = useStaticQuery(
        graphql`
        query {
            quote1: file(relativePath: { eq: "quotes/quote-1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          quote2: file(relativePath: { eq: "quotes/quote-2.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          quote3: file(relativePath: { eq: "quotes/quote-3.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          quote4: file(relativePath: { eq: "quotes/quote-4.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          quote5: file(relativePath: { eq: "quotes/quote-5.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `
    )

    const quote1ImageData = data.quote1.childImageSharp.fluid;
    const quote2ImageData = data.quote2.childImageSharp.fluid;
    const quote3ImageData = data.quote3.childImageSharp.fluid;
    const quote4ImageData = data.quote4.childImageSharp.fluid;
    const quote5ImageData = data.quote5.childImageSharp.fluid;

    return (
        <Section id="quotes">
            <Content>
                <Heading>
                What our athletes say
                </Heading>
                <Copy>
                Let the kids do the talking...
                </Copy>
            </Content>
            <TileContainer>
                <Tile href="" title="">
                    <TileImage fluid={quote1ImageData} />
                </Tile>
                <Tile href="" title="">
                    <TileImage fluid={quote2ImageData} />
                </Tile>
                <Tile href="" title="">
                    <TileImage fluid={quote3ImageData} />
                </Tile>
                <Tile href="" title="">
                    <TileImage fluid={quote4ImageData} />
                </Tile>
                <Tile href="" title="">
                    <TileImage fluid={quote5ImageData} />
                </Tile>
            </TileContainer>
        </Section>
    )
}

export default QuotesSection;
