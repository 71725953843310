import React from "react"
import { Global, css } from '@emotion/react'
import styled from "@emotion/styled";
import SEO from "../components/seo";
import Header from "../components/header";
import Footer from "../components/footer";
import SchoolGamesHeroSection from '../components/sections/schoolGamesHeroSection';
import YouthDevelopmentSection from '../components/sections/youthDevelopmentSection';
import HallOfFameSection from '../components/sections/hallOfFameSection';
import QuotesSection from '../components/sections/quotesSection';
import BringHappinessSection from '../components/sections/bringHappinessSection';

const globalCss = css`
    @font-face {
        font-family: 'Brandon';
        src: url('/fonts/Brandon_reg.otf') format('opentype');
        font-display: swap;
    }

    @font-face {
        font-family: 'Brandon';
        src: url('/fonts/Brandon_bld.otf') format('opentype');
        font-weight: bold;
        font-display: swap;
    }

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-family: "Brandon";
    margin: 0;
    padding: 0;
  }
`;

const OrangeSection = styled.section`
    background: radial-gradient(circle at center, #ffc43e 20%, #f68507 60%);
`;

const SchoolGamesPage = () => {
  return (
    <>
      <SEO title="School Games - Maltina" />
      <main>
        <Global styles={globalCss} />
        <OrangeSection>
          <Header compact={true} />
        </OrangeSection>

        <SchoolGamesHeroSection />
        <YouthDevelopmentSection />
        <HallOfFameSection />
        <QuotesSection />
        <BringHappinessSection />
        <Footer />
      </main>
    </>
  )
}

export default SchoolGamesPage
