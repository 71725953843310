import React from "react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import ButtonOutline from "../buttonOutline";

const Section = styled.section`

`;

const Background = styled(BackgroundImage)`
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 5rem;

    @media (max-width: 60rem) {
        padding: 1rem;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    max-width: 30rem;
    margin: 1rem 0;

    @media (max-width: 60rem) {
        align-items: center;
        background-color: rgb(0, 0, 0, 0.3);
        padding: 0.5rem;
    }
`;

const Heading = styled.h3`
    color: #fff;
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: 0;
    text-align: right;

    @media (max-width: 60rem) {
        text-align: center;
    }
`;

const Copy = styled.p`
    font-size: 1.2rem;
    color: #fff;
    text-align: right;

    @media (max-width: 60rem) {
        text-align: center;
    }
`;

const HallOfFameSection = () => {
    const data = useStaticQuery(
        graphql`
        query {
          background: file(relativePath: { eq: "sections/hall-of-fame.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `
    );

    const backgroundData = data.background.childImageSharp.fluid;

    return (
        <Section id="hall-of-fame">
            <Background fluid={backgroundData}>
                <Content>
                    <Heading>
                        Hall of Fame
                    </Heading>
                    <Copy>
                        Allow us present to you the kids who turned heads and brought the heat, the ones who saw beyond the fun and brought their A-game to the competition, our very own hall of famers!
                    </Copy>
                    <ButtonOutline href="/hall-of-fame">
                        View Hall of Fame
                    </ButtonOutline>
                </Content>
            </Background>
        </Section>
    )
}

export default HallOfFameSection;
